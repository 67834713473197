<template>
  <div>
    <v-skeleton-loader class="mx-auto ml-2 mr-2" type="card" :loading="loading">
      <!-- <v-container fluid class="mb-4"> -->
      <CommunityInfo
        :communityInfo="communityInfo"
        @onRelatedCommunityChanged="showRelatedCommunity"
      />
      <InternalBreadcrumbs :breadcrumbs="breadcrumbs" />
      <v-card elevation="0" outlined :loading="requestProcessing">
        <template slot="progress">
          <v-progress-linear height="10" indeterminate></v-progress-linear>
          <p class="mt-4 text-center">Processing...</p>
        </template>
        <v-card-title v-if="!requestProcessing">
          Quarterly Plan Template
        </v-card-title>
        <v-card-text v-if="!requestProcessing">
          <!-- Start Quarterly Plan section -->
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model="planTemplate.title"
                  label="Quarterly Plan Template Name"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="6" md="3">
                <v-select
                  v-model="planQuarter"
                  :items="planQuarterItems"
                  item-text="name"
                  item-value="value"
                  label="Select Quarter..."
                  required
                ></v-select>
              </v-col>
            </v-row>
            <p>
              Add each task individually for each 30 day period. Drag and drop
              any individual task to reorder.
            </p>
            <v-row>
              <v-col cols="12" md="8">
                <hr />
                <h6>{{ month1Name }}</h6>
                <div>
                  <v-card class="tasksArea" elevation="0" outlined>
                    <v-card-title>
                      <v-btn
                        x-small
                        v-if="!tasks30AddInProcess"
                        @click="tasks30AddInProcess = true"
                      >
                        + Add Task
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <div v-if="tasks30AddInProcess">
                        <v-textarea
                          v-model="taskContent"
                          solo
                          dense
                        ></v-textarea>
                        <v-btn x-small @click="addTaskToPlan(30)">
                          Add to Plan
                        </v-btn>
                      </div>
                      <table width="100%" class="mt-2">
                        <tr
                          v-for="(item, i) in tasksFor30Days"
                          :key="'tr30' + i"
                        >
                          <td width="80%">
                            <p v-if="!item.editInProcess">
                              {{ item.description }}
                            </p>
                            <v-textarea
                              v-if="item.editInProcess"
                              v-model="item.descriptionCopy"
                              solo
                              dense
                            ></v-textarea>
                          </td>
                          <td width="20%">
                            <v-btn
                              x-small
                              v-if="!item.editInProcess"
                              @click="startEdit(item)"
                            >
                              Edit </v-btn
                            ><v-btn
                              x-small
                              v-if="!item.editInProcess"
                              @click="deleteItem(30, i)"
                            >
                              Delete
                            </v-btn>
                            <v-btn
                              x-small
                              v-if="item.editInProcess"
                              @click="cancelEdit(item)"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              x-small
                              v-if="item.editInProcess"
                              @click="confirmEdit(item)"
                            >
                              Save
                            </v-btn>
                          </td>
                        </tr>
                      </table>
                    </v-card-text>
                  </v-card>
                  <h6 class="mt-2">{{ month1Name }} Notes</h6>
                  <v-textarea v-model="planTemplate.day30" solo></v-textarea>
                </div>
                <hr />
                <h6>{{ month2Name }}</h6>
                <div>
                  <v-card class="tasksArea" elevation="0" outlined>
                    <v-card-title>
                      <v-btn
                        x-small
                        v-if="!tasks60AddInProcess"
                        @click="tasks60AddInProcess = true"
                      >
                        + Add Task
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <div v-if="tasks60AddInProcess">
                        <v-textarea
                          v-model="taskContent"
                          solo
                          dense
                        ></v-textarea>
                        <v-btn x-small @click="addTaskToPlan(60)">
                          Add to Plan
                        </v-btn>
                      </div>
                      <table width="100%" class="mt-2">
                        <tr
                          v-for="(item, i) in tasksFor60Days"
                          :key="'tr60' + i"
                        >
                          <td width="80%">
                            <p v-if="!item.editInProcess">
                              {{ item.description }}
                            </p>
                            <v-textarea
                              v-if="item.editInProcess"
                              v-model="item.descriptionCopy"
                              solo
                              dense
                            ></v-textarea>
                          </td>
                          <td width="20%">
                            <v-btn
                              x-small
                              v-if="!item.editInProcess"
                              @click="startEdit(item)"
                            >
                              Edit </v-btn
                            ><v-btn
                              x-small
                              v-if="!item.editInProcess"
                              @click="deleteItem(60, i)"
                            >
                              Delete
                            </v-btn>
                            <v-btn
                              x-small
                              v-if="item.editInProcess"
                              @click="cancelEdit(item)"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              x-small
                              v-if="item.editInProcess"
                              @click="confirmEdit(item)"
                            >
                              Save
                            </v-btn>
                          </td>
                        </tr>
                      </table>
                    </v-card-text>
                  </v-card>
                  <h6 class="mt-2">{{ month2Name }} Notes</h6>
                  <v-textarea v-model="planTemplate.day60" solo></v-textarea>
                </div>
                <hr />
                <h6>{{ month3Name }}</h6>
                <div>
                  <v-card class="tasksArea" elevation="0" outlined>
                    <v-card-title>
                      <v-btn
                        x-small
                        v-if="!tasks90AddInProcess"
                        @click="tasks90AddInProcess = true"
                      >
                        + Add Task
                      </v-btn>
                    </v-card-title>
                    <v-card-text>
                      <div v-if="tasks90AddInProcess">
                        <v-textarea
                          v-model="taskContent"
                          solo
                          dense
                        ></v-textarea>
                        <v-btn x-small @click="addTaskToPlan(90)">
                          Add to Plan
                        </v-btn>
                      </div>
                      <table width="100%" class="mt-2">
                        <tr
                          v-for="(item, i) in tasksFor90Days"
                          :key="'tr90' + i"
                        >
                          <td width="80%">
                            <p v-if="!item.editInProcess">
                              {{ item.description }}
                            </p>
                            <v-textarea
                              v-if="item.editInProcess"
                              v-model="item.descriptionCopy"
                              solo
                              dense
                            ></v-textarea>
                          </td>
                          <td width="20%">
                            <v-btn
                              x-small
                              v-if="!item.editInProcess"
                              @click="startEdit(item)"
                            >
                              Edit
                            </v-btn>
                            <v-btn
                              x-small
                              v-if="!item.editInProcess"
                              @click="deleteItem(90, i)"
                            >
                              Delete
                            </v-btn>
                            <v-btn
                              x-small
                              v-if="item.editInProcess"
                              @click="cancelEdit(item)"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              x-small
                              v-if="item.editInProcess"
                              @click="confirmEdit(item)"
                            >
                              Save
                            </v-btn>
                          </td>
                        </tr>
                      </table>
                    </v-card-text>
                  </v-card>
                  <h6 class="mt-2">{{ month3Name }} Notes</h6>
                  <v-textarea v-model="planTemplate.day90" solo></v-textarea>
                </div>
              </v-col>
            </v-row>
          </v-container>
          <!-- End Quarterly Plan section -->
        </v-card-text>
        <v-card-actions v-if="!requestProcessing">
          <v-row>
            <v-col cols="12" md="9" class="text-right">
              <v-btn
                color="primary"
                class="mx-1"
                @click="saveQuarterlyPlanTemplate"
                >Save Quarterly Plan Template</v-btn
              >

              <router-link
                class="blue--text mx-1"
                :to="`/customers/${customerId}/planTemplates`"
              >
                <v-btn>Cancel</v-btn>
              </router-link>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>

      <!-- </v-container> -->
    </v-skeleton-loader>
  </div>
</template>

<script>
import CommunityInfo from "@/view/components/CommunityInfo.vue";
import InternalBreadcrumbs from "@/view/components/DashboardInternalBreadcrumbs.vue";
import { SET_BREADCRUMB } from "@/core/store/breadcrumbs.module";
import {
  GET_QUERY,
  API_CUSTOMERS,
  POST_DATA,
  API_QUARTERLY_PLAN_TEMPLATES
} from "@/core/store/crud.module";
import crud from "@/core/helpers/crud";
// import dateFormatter from "@/core/helpers/dateFormatter";
import stringFormatter from "@/core/helpers/stringFormatter";
import { validationMixin } from "vuelidate";
// import dataModels from "@/core/helpers/datamodels.js";
// import { required } from "vuelidate/lib/validators";
// import moment from "moment";

export default {
  mixins: [validationMixin],
  components: {
    CommunityInfo,
    InternalBreadcrumbs
  },
  data: () => ({
    communityInfo: {},
    planTemplate: {
      day30: "",
      day60: "",
      day90: ""
    },
    planQuarter: "1",
    planQuarterItems: [
      { name: "Q1", value: "1" },
      { name: "Q2", value: "2" },
      { name: "Q3", value: "3" },
      { name: "Q4", value: "4" }
    ],
    tasks30AddInProcess: false,
    tasks60AddInProcess: false,
    tasks90AddInProcess: false,
    taskContent: "",
    tasksFor30Days: [],
    tasksFor60Days: [],
    tasksFor90Days: [],
    relatedCommunity: null,
    relatedCommunityItems: [],
    options: {
      page: 1,
      itemsPerPage: 5
    },
    serviceAlert: {},
    pageText: "",
    startingAfter: "",
    endingBefore: "",
    previousPage: 1,
    hasMore: false,
    isLoading: false,
    loading: false,
    snackbarContent: "",
    snackbarColor: "#F06835",
    breadcrumbs: [],
    requestProcessing: false,
    month1Name: "30 Days",
    month2Name: "60 Days",
    month3Name: "90 Days"
  }),
  props: {
    customerId: {
      type: [Number, String],
      default: 0
    }
  },
  validations() {
    return true;
  },
  watch: {
    planQuarter: function() {
      if (this.planQuarter == "1") {
        this.month1Name = "January";
        this.month2Name = "February";
        this.month3Name = "March";
      } else if (this.planQuarter == "2") {
        this.month1Name = "April";
        this.month2Name = "May";
        this.month3Name = "June";
      } else if (this.planQuarter == "3") {
        this.month1Name = "July";
        this.month2Name = "August";
        this.month3Name = "September";
      } else if (this.planQuarter == "4") {
        this.month1Name = "October";
        this.month2Name = "November";
        this.month3Name = "December";
      }
    }
  },
  async mounted() {
    // this.loading = true;
    // await this.getComunityInfo();
    // await this.createBreadcrumb();
    // await this.setInternalBreadcrumbs();
    // this.loading = false;
  },
  async created() {
    await this.getComunityInfo();
    if (this.$route.query.sourceTemplateId)
      await this.getQuarterlyPlanTemplate();
  },
  methods: {
    startEdit(item) {
      item.editInProcess = true;
      item.descriptionCopy = item.description;
    },
    deleteItem(days, index) {
      let array = this.tasksFor30Days;
      if (days === 30) {
        array = this.tasksFor30Days;
      }

      if (days === 60) {
        array = this.tasksFor60Days;
      }

      if (days === 90) {
        array = this.tasksFor90Days;
      }

      array.splice(index, 1);
    },
    cancelEdit(item) {
      item.editInProcess = false;
      item.descriptionCopy = "";
    },
    confirmEdit(item) {
      item.editInProcess = false;
      item.description = item.descriptionCopy;
    },
    addTaskToPlan(days) {
      let arrayToAdd = this.tasksFor30Days;
      if (days === 30) {
        arrayToAdd = this.tasksFor30Days;
        this.tasks30AddInProcess = false;
      }

      if (days === 60) {
        arrayToAdd = this.tasksFor60Days;
        this.tasks60AddInProcess = false;
      }

      if (days === 90) {
        arrayToAdd = this.tasksFor90Days;
        this.tasks90AddInProcess = false;
      }
      let tasksCount = arrayToAdd.length;
      arrayToAdd.push({
        description: this.taskContent,
        id: -tasksCount,
        editInProcess: false,
        descriptionCopy: ""
      });

      this.taskContent = "";
    },
    showRelatedCommunity(communityId) {
      this.$router.push({
        name: "CustomerDetails",
        params: { customerId: communityId }
      });
    },
    async getComunityInfo() {
      this.loading = true;

      this.isLoading = true;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: API_CUSTOMERS,
          id: this.customerId
        })
        .then(response => {
          if (response.status >= 200 || response.status <= 204) {
            this.communityInfo = crud.getPopulatedComunityInfo(response.data);
            this.createBreadcrumb();
            this.setInternalBreadcrumbs();

            this.plan = {
              quarter: "1",
              day30: "",
              day60: "",
              day90: ""
            };
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
          this.isLoading = false;
        });
    },
    async getQuarterlyPlanTemplate() {
      this.loading = true;

      this.isLoading = true;
      let url = "";

      if (this.$route.query.sourceTemplateId)
        url = `${API_QUARTERLY_PLAN_TEMPLATES}/${this.$route.query.sourcePlanId}`;

      await this.$store
        .dispatch(GET_QUERY, {
          listName: url
        })
        .then(response => {
          this.planTemplate = {
            quarter: "1",
            day30: "",
            day60: "",
            day90: ""
          };
          if (response.status >= 200 || response.status <= 204) {
            this.planTemplate = response.data;
            if (this.planTemplate) {
              this.tasksFor30Days = this.planTemplate.day30Tasks.$values.map(
                i => {
                  return {
                    id: 0,
                    description: i.description,
                    weight: 1,
                    status: 0,
                    editInProcess: false,
                    descriptionCopy: ""
                  };
                }
              );

              this.tasksFor60Days = this.planTemplate.day60Tasks.$values.map(
                i => {
                  return {
                    id: 0,
                    description: i.description,
                    weight: 1,
                    status: 0,
                    editInProcess: false,
                    descriptionCopy: ""
                  };
                }
              );

              this.tasksFor90Days = this.planTemplate.day90Tasks.$values.map(
                i => {
                  return {
                    id: 0,
                    description: i.description,
                    weight: 1,
                    status: 0,
                    editInProcess: false,
                    descriptionCopy: ""
                  };
                }
              );
            }
          } else {
            this.$snackbar.showMessage({
              content: response.data || "Something went wrong!",
              color: "red"
            });
          }

          this.loading = false;
          this.isLoading = false;
        });
    },

    async setInternalBreadcrumbs() {
      this.breadcrumbs = [
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Quarterly Plans",
          route: "/customers/" + this.customerId + "/plans"
        },
        {
          title: "Quarterly Plan Templates",
          route: "/customers/" + this.customerId + "/planTemplates"
        },
        { title: "New Quarterly Plan Template" }
      ];
    },
    createBreadcrumb() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Customers", route: "/customers" },
        {
          title: this.communityInfo.name,
          route: "/customers/" + this.customerId
        },
        {
          title: "Quarterly Plans",
          route: "/customers/" + this.customerId + "/plans"
        },
        {
          title: "Quarterly Plan Templates",
          route: "/customers/" + this.customerId + "/planTemplates"
        },
        { title: "New Quarterly Plan Template" }
      ]);
    },
    saveQuarterlyPlanTemplate() {
      this.planTemplate.branch = this.communityInfo.branch.name;

      this.planTemplate.isActive = true;

      if (!this.planTemplate.title) {
        this.$snackbar.showMessage({
          content: "Please, enter template name",
          color: "red"
        });
        return;
      }

      if (!this.planQuarter) {
        this.$snackbar.showMessage({
          content: "Please, enter quarter",
          color: "red"
        });
        return;
      }

      this.planTemplate.title =
        this.planTemplate.title || "Q" + this.planQuarter;
      this.planTemplate.quarter = this.planQuarter;

      var self = this;

      this.planTemplate.day30Tasks = this.tasksFor30Days.map(i => {
        return { id: 0, description: i.description, weight: 1, status: 0 };
      });

      this.planTemplate.day60Tasks = this.tasksFor60Days.map(i => {
        return { id: 0, description: i.description, weight: 1, status: 0 };
      });

      this.planTemplate.day90Tasks = this.tasksFor90Days.map(i => {
        return { id: 0, description: i.description, weight: 1, status: 0 };
      });

      let url = `${API_QUARTERLY_PLAN_TEMPLATES}`;
      let action = POST_DATA;

      this.requestProcessing = true;

      this.$store
        .dispatch(action, {
          listName: url,
          data: this.planTemplate
        })
        .then(response => {
          if (response) {
            self.snackbarContent =
              "Quarterly Plan Template has been saved successfully";
            self.$router.push({
              name: "CustomerQuarterlyPlanTemplates",
              params: { customerId: self.customerId }
            });
          } else {
            self.snackbarContent = "Something went wrong!"; //response.data;
            self.snackbarColor = "red";
          }
          self.$snackbar.showMessage({
            content: self.snackbarContent,
            color: self.snackbarColor
          });

          self.confirmDialog = self;
          self.requestProcessing = false;
        })
        .catch(err => {
          self.$snackbar.showMessage({
            content: err,
            color: self.snackbarColor
          });
          self.requestProcessing = false;
        });
    },

    async submit() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      }
    },
    capitalizeFirstLetter(string) {
      return stringFormatter.capitalizeFirstLetter(string);
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    }
  },
  computed: {}
};
</script>
